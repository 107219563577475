
export class dbConst {

  public static users = 'users';
  public static bars = 'bars';
  public static barSettings = 'barSettings';
  public static units = 'units';
  public static beverages = 'beverages';
  public static beerstyles = 'beerstyles';
  public static kegs = 'kegs';
  public static bottles = 'bottles';
  public static kegs_inventoryKey = 'inventoryKey';
  public static taps = 'taps';
  public static ontapBasics = 'ontaps';
  public static ontap2s = 'ontap2s';
  public static onTapAdvancedCardTemplate = 'ontapAdvancedCardTemplates';
  public static ontap2Templates = 'ontapTemplates';
  public static kegtrons = 'kegtrons';
  public static plaatoKegs = 'plaatoKegs';
  public static customKegMons = 'customKegMons';
  public static statistics = 'statistics';
  public static settings = 'settings';
  public static brewfather = 'brewfather';
  public static untappd = 'untappd';
  public static externalApiSettings = 'externalApiSettings';
  public static audit = 'audit';
  public static subscriptions = 'subscriptions';
  public static metadata = 'metadata';
  public static servingSize = 'servingSizes';
  public static locations = 'locations';
  public static fonts = 'fonts';
  public static notifications = 'notifications';
  public static stripeCustomers = 'stripeCustomers';
  public static stripeProducts = 'stripeProducts';
  public static stripePrices = 'prices';

}

export class regionConst {
  public static europeWest1 = 'europe-west1';
}
