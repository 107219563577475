import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OnTapAdvancedService } from 'app/services/dal/on-tap-advanced.service';
import { OnTapBasicService } from 'app/services/dal/on-tap-basic.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as Action from './actions'
import { ToastService } from 'app/services/toast.service';
import { OnTapAdvancedCardTemplateService } from 'app/services/dal/on-tap-advanced-card-template.service';



@Injectable()
export class Effects {

  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private service: OnTapAdvancedCardTemplateService) { }

  getItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.loadItems),
        mergeMap((action) => this.service.getItems()
          .pipe(
            map(items => Action.loadItemsSuccess({ items: items })),
            catchError(error => of(Action.loadItemsFailure({ error })))
          )
        )
      );
  });



  // updateItem$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(TapActions.updateItem),
  //       mergeMap((action) => of(this.service.update(action.item))
  //         .pipe(
  //           map(() => {
  //             this.toastService.saveSuccess();
  //             return TapActions.updateItemSuccess({ item: action.item });
  //           }),
  //           catchError(error => of(TapActions.updateItemFailure({ error })))
  //         )
  //       )
  //     );
  // });



  // add$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(TapActions.addItem),
  //       mergeMap((action) => of(this.service.add(action.item, action.id))
  //         .pipe(
  //           map(() => {
  //             this.toastService.saveSuccess();
  //             return TapActions.addItemSuccess({ item: action.item });
  //           }),
  //           catchError(error => of(TapActions.addItemFailure({ error })))
  //         )
  //       )
  //     );
  // });


  // delete$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(TapActions.deleteItem),
  //       mergeMap((action) => of(this.service.delete(action.id))
  //         .pipe(
  //           map(() => {
  //             this.toastService.deleteItemSuccess();
  //             return TapActions.deleteItemSuccess();
  //           }),
  //           catchError(error => of(TapActions.addItemFailure({ error })))
  //         )
  //       )
  //     );
  // });


  // softUpdate$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(TapActions.softUpdate),
  //       mergeMap((action) => from(this.service.softUpdate(action.id, action.data, action.uid))
  //         .pipe(
  //           map(() => {
  //             this.toastService.saveSuccess();
  //             return TapActions.softUpdateSuccess({ id: action.id, data: action.data })
  //           }),
  //           catchError((error) => {
  //             return of(TapActions.softUpdateFailure({ error }))
  //           })
  //         )
  //       )
  //     );
  // });


}
