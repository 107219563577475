import { createAction, props } from '@ngrx/store';
import { INotification } from 'app/models/notification';

export const clearAllItems = createAction('[DalHelper] Clear All Notifications');

// export const loadItems = createAction('[Notification Page] Load Notifications', props<{ barId: string }>());
export const loadReadItems = createAction('[DalHelper] Load Read Notification', props<{ barId: string, limit: number }>());
export const loadUnreadItems = createAction('[DalHelper] Load UnRead Notification', props<{ barId: string, limit: number }>());
// export const loadItemsFromInitEffect = createAction('[Notification Effect Initiator] Load Notification', props<{ barId: string }>());
export const loadItemsFailure = createAction('[Notification API] Load Notifications Failure', props<{ error: any }>());
export const loadItemsSuccess = createAction('[Notification API] Load Notifications Success', props<{ items: INotification[] }>());
export const loadUnreadItemsSuccess = createAction('[Notification API] Load Unread Notifications Success', props<{ barId: string, items: INotification[] }>());

export const loadUnreadItemsAfter = createAction('[Notification Effect Initiator] Load UnRead Notification After', props<{ barId: string, startAfter: string }>());
export const loadReadItemsAfter = createAction('[Notification Effect Initiator] Load Read Notification After', props<{ barId: string, startAfter: string }>());
export const loadAllItemsAfter = createAction('[Notification Effect Initiator] Load All Notification After', props<{ barId: string, startAfter: string }>());
export const loadItemsAfterFailure = createAction('[Notification API] Load Notifications After Failure', props<{ error: any }>());
export const loadItemsAfterSuccess = createAction('[Notification API] Load Notifications After Success', props<{ items: INotification[] }>());

export const markAllVisibleAsRead = createAction('[Notification List Page] Mark All Visible Notifications as Read', props<{ items: INotification[] }>());
export const markAllVisibleAsReadSuccess = createAction('[Notification API] Mark All Visible Notifications as Read Success');
export const markAllVisibleAsReadFailure = createAction('[Notification API] Mark All Visible Notifications as Read Failure', props<{ error: any }>());

export const markAllAsRead = createAction('[Notification List Page] Mark All Notifications as Read', props<{ barId: string }>());
export const markAllAsReadSuccess = createAction('[Notification API] Mark All Notifications as Read Success');
export const markAllAsReadFailure = createAction('[Notification API] Mark All Notifications as Read Failure', props<{ error: any }>());

export const deleteAllReadTapNotifications = createAction('[Notification List Page] Delete All Read Tap Notifications', props<{ barId: string }>());
export const deleteAllReadTapNotificationsSuccess = createAction('[Notification API] Delete All Read Tap Notifications Success');
export const deleteAllReadTapNotificationsFailure = createAction('[Notification API] Delete All Read Tap Notifications Failure', props<{ error: any }>());

export const deleteAllTapNotifications = createAction('[Notification List Page] Delete All Tap Notifications', props<{ barId: string }>());
export const deleteAllTapNotificationsSuccess = createAction('[Notification API] Delete All Tap Notifications Success');
export const deleteAllTapNotificationsFailure = createAction('[Notification API] Delete All Tap Notifications Failure', props<{ error: any }>());

export const updateItem = createAction('[Notification Detail Page] Update Notification', props<{ item: INotification }>());
export const updateItemSuccess = createAction('[Notification API] Update Notification Success', props<{ item: INotification }>());
export const updateItemFailure = createAction('[Notification API] Update Notification Failure', props<{ error: any }>());

export const addItem = createAction('[Notification Detail Page] Add Notification', props<{ id: string, item: INotification }>());
export const addItemSuccess = createAction('[Notification API] Add Notification Success', props<{ item: INotification }>());
export const addItemFailure = createAction('[Notification API] Add Notification Failure', props<{ error: any }>());


export const deleteItem = createAction('[Notification Detail Page] Delete Notification', props<{ id: string, route: string }>());
export const deleteItemSuccess = createAction('[Notification API] Delete Notification Success', props<{ id: string, route: string }>());
export const deleteItemFailure = createAction('[Notification API] Delete Notification Failure', props<{ error: any }>());


export const setItem = createAction('[Notification Detail Page] Set Selected Item', props<{ id: string }>());
export const setItemFromListPage = createAction('[Notification List Page] Set Selected Item', props<{ id: string }>());


export const softUpdate = createAction('[Notification API] Soft Update Notification', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[Notification API] Soft Update Notification Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[Notification API] Soft Update Notification Failure', props<{ error: any }>());

export const setNotificationRoute = createAction('[Notification Page] Set route', props<{ route: string }>());



//export const updateBeverageOnNotification = createAction('[Notification Detail Page] Update Beverage On Notification', props<{ beverage: IBeverage }>());
