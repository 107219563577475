import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as OnTapActions from './actions'
import { IOnTapAdvancedCardTemplate } from 'app/models/on-tap-template';

export interface State extends EntityState<IOnTapAdvancedCardTemplate> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}


export function sortByName(a: IOnTapAdvancedCardTemplate, b: IOnTapAdvancedCardTemplate): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: IOnTapAdvancedCardTemplate): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IOnTapAdvancedCardTemplate> = createEntityAdapter<IOnTapAdvancedCardTemplate>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

export const stateName = 'ontapadvancedcardtemplate';

export const reducer = createReducer<State>(
  initialState,
  on(OnTapActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(OnTapActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(OnTapActions.loadItemsFailure, (state, action) => {
    return {
      ...state,
      entities: {},
      error: action.error
    };
  }),
);

// get the selectors
const { selectAll, selectEntities } = adapter.getSelectors();

const onTapBasicState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(onTapBasicState, selectAll);
export const selectAllEntities = createSelector(onTapBasicState, selectEntities);
export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);
